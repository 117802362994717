<template>

	<div class="cont" id="p24">
		<div class="c-list">
			<div class="c01">
				<div class="c-title">공지사항</div>
			</div>
			<div class="c02">
				<div class="board">
					<div class="board-header">
						<div class="board-item">
							<div class="td" style="text-align: left !important; padding-left: 10px;">{{ item.title }}</div>
							<div class="td" style="text-align: right !important; padding-right: 10px;">{{ item.createAt }}</div>
						</div>
					</div>
					<div class="board-body">
						<div class="board-item" style="height: 450px; padding: 10px;" v-html="$options.filters.nl2br(item.content)">
							
						</div>
					</div>
				</div>
				
				<div class="mt-10 text-center">
					<v-btn @click="$router.go(-1)">목록</v-btn>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { Axios } from '@/resources/axios/axios'
	import { filter } from '@/resources/filter/filter'
	
	export default{
		name: 'NoticeList'
		,props: ['user']
		,components: {  }
		,data: function(){
			return {
				items: [
				]
				,item: {
				}
				,search: {
					keyword: ''
				}
				,showPopup: {
					detail: false
				}
			}
		}
		,filter: filter
		,methods: {
			getData: async function(){
				try{
					const result = await Axios({
						method: 'get'
						,url: '/notices/' + this.$route.params.bbsSeq
						,authorize: true
					})
					if(result.success){
						this.item = result.data.content
					}else{
						this.$emit('setNotify', { type: 'error', message: result.message })
					}
				}catch(E){
					console.log(E)
					this.$emit('setNotify', { type: 'error', message: E })
				}
			}
			,setOverlay: function(type){
				this.$emit('setOverlay', type)
			}
			,getItem: async function(bbsSeq){
				if(!bbsSeq){
					this.$emit('setNotify', { type: 'error', message: '공지사항 정보가 없습니다'})
					return false
				}
				try{
					const result = await Axios({
						method: 'get'
						,url: '/notices/' + bbsSeq
						,authorize: true
					})
					if(result.success){
						this.item = result.data.content
						this.setOverlay(true)
						this.showPopup.detail = true
					}else{
						this.$emit('setNotify', { type: 'error', message: result.message })
					}
				}catch(E){
					console.log(E)
					this.$emit('setNotify', { type: 'error', message: E })
				}
			}
		}
		,created: function(){
			this.getData()
		}
	}
</script>











